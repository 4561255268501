import { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";

const ContactForm = () => {
  const [validated, setValidated] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [showSuccess, setShowSuccess] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    if (form.checkValidity() === true) {
      event.preventDefault();
      const xhr = new XMLHttpRequest();
      xhr.open('POST', 'process_form.php');
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      xhr.onload = () => {
        if (xhr.status === 200) {
          setShowSuccess(true);
          setTimeout(() => {
            setShowSuccess(false);
          }, 3000);
        } else {
          console.error(xhr.responseText);
        }
      };
      xhr.send(`name=${formValues.name}&email=${formValues.email}&subject=${formValues.subject}&message=${formValues.message}`);
    }
  };


  const handleInputChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Form
      name="contact"
      method="POST"
      action="process_form.php"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className="contact-form"
    >
      <Form.Group controlId="formName">
        <Form.Control
          required
          type="text"
          placeholder={"Entrez votre nom"}
          name="name"
          value={formValues.name}
          onChange={handleInputChange}
        />
        <Form.Control.Feedback type="invalid">
          Veuillez saisir votre nom.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formEmail">
        <Form.Control
          required
          type="email"
          placeholder="Entrez votre email"
          name="email"
          value={formValues.email}
          onChange={handleInputChange}
        />
        <Form.Control.Feedback type="invalid">
          Veuillez entrer une adresse email valide.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formName">
        <Form.Control
          required
          type="text"
          placeholder="Entrez le sujet"
          name="subject"
          value={formValues.subject}
          onChange={handleInputChange}
        />
        <Form.Control.Feedback type="invalid">
          Veuillez saisir un sujet.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formMessage">
        <Form.Control
          required
          as="textarea"
          rows="3"
          placeholder="Entrez le message"
          name="message"
          value={formValues.message}
          onChange={handleInputChange}
        />
        <Form.Control.Feedback type="invalid">
          Veuillez saisir un message.
        </Form.Control.Feedback>
      </Form.Group>
      <Button type="submit" name="submit">
        Envoyer
      </Button>
      {showSuccess && (
        <Alert variant="success" className="mt-3">
          Votre message a été envoyé avec succès.
        </Alert>
      )}
    </Form>
  );
};

export default ContactForm;