export const phoneNumber = "+33 6 20 85 38 13";
export const phoneNumberLink = "https://wa.me/+33620853813";
export const email = "contact@heavenofweb.fr";
export const emailLink = "mailto:contact@heavenofweb.fr";
export const adresse = "39 rue du faubourg joly - 49330 les haut danjou";
export const facebookLink = "https://www.facebook.com/profile.php?id=100087521436683";
export const twitterLink = "https://twitter.com/heavenofweb1";
export const instagramLink = "https://www.instagram.com/heavenofweb/?hl=fr";
export const linkedinLink = "https://www.linkedin.com/company/heaven-of-web";
export const githubLink = "https://github.com/heavenofweb";
